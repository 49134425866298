import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { useTheme, makeStyles } from '@material-ui/styles'
import EmailSubmissionBanner from '../components/page/index/emailSubmissionBanner'
import ResponsiveRhizomeLogo from '../components/image/responsiveRhizomeLogo'
import ResponsiveBackslashArtLogo from '../components/image/responsiveBackslashArtLogo'

const indexStyles = makeStyles(theme => ({
  mainText1: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  mainText2: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}))
export default function IndexPage () {
  return (
    <Layout>
      <SEO title='7000x7000' />
      <IndexPageInner />
    </Layout>
  )
}

// Need theme to be accessible, so have to seperate this from IndexPage
function IndexPageInner () {
  const c = indexStyles()
  const theme = useTheme()
  return (
    <Box py={2} px={2}>
      <MainText className={c.mainText1} />
      <Box py={2} />
      <EmailSubmissionBanner />
      <Box py={2} />
      <MainText className={c.mainText2} />
      <Credits />
      <Box py={3} />
    </Box>
  )
}

// **********************************
function MainText (props) {
  return (
    <Container maxWidth='lg' {...props}>
      <Typography variant='h4'>
        7000x7000 是 <a href="https://dac.taipei/">DAC</a>與紐約 <a href="https://rhizome.org">Rhizome</a>合作推動的一個特別計畫。目的在提供一個平台讓藝術家與科技人能夠配對合作，一起創造發展嶄新的想法。
      </Typography>
      <br />
      <Typography variant='h4'>
        {'\u9999'} is a special partnership between <a href="https://dac.taipei/">DAC</a> and <a href="https://rhizome.org">Rhizome</a> to bring together up-and-coming artists and technologists to collaborate and create new things.
        <sup style={{ fontSize: '20px', marginLeft: '-10px' }}>{'\u00d7'}</sup>
      </Typography>
    </Container>
  )
}

function Credits (props) {
  return (
    <Container maxWidth='lg' {...props}>
      <Typography variant='h5' style={{'text-align': 'center'}}>
        The 7000x7000 platform was developed by Rhizome and /Art at Cornell Tech.
      </Typography>
      <Typography variant='h5' style={{'text-align': 'center'}}>
        7000x7000.tw is made possible by Taipei Cultural Center in New York.
      </Typography>
    </Container>
  )
}
