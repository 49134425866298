import React, { useState } from 'react'
import { SubmitEmail } from '../../../client/client'
import { Box, Typography, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const getFormStyles = makeStyles(theme => ({
  startButton: {
    backgroundColor: 'black',
    color: 'white',
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: 'ArialCustom7x7, Arial, sans-serif',
    borderRadius: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '0px',
    paddingTop: '4px',
    '@media(min-width:620px)': {
      marginLeft: '10px'
    },
    boxShadow: 'none',
    '&:hover': {
      color: 'rgb(0,255,0) ',
      backgroundColor: 'black'
    },
    '&:active': {
      boxShadow: 'none'
    }
  },
  emailField: {
    border: '1px solid black',
    padding: '14px 10px',
    marginBottom: '10px',
    marginRight: '10px',
    fontSize: '20px',
    fontFamily: 'ArialCustom7x7, Arial, sans-serif',
    backgroundColor: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
      marginRight: '10px',
      fontSize: '26px'
    },
    '&:focus': {
      outline: 'none',
      color: 'rgb(0,255,0) !important;'
    },
    '&:active': {
      color: 'rgb(0,255,0) !important;'
    },
    '&:visited': {
      color: 'rgb(0,255,0) !important;'
    },
    '&:hover': {
      color: 'rgb(0,255,0) !important;'
    }
  },
  emailForm: {
    textAlign: 'center',
    position: 'relative'
  },

  emailFieldRow: {
    '@media(min-width:700px)': {
      width: '65%',
      margin: '0 auto'
    }
  }
}))
export default function EmailSubmissionBanner () {
  return (
    <Box maxWidth={{ xs: '100%', sm: '850px' }} margin='0 auto'>
      <Box border={2} pt={2} px={2}>
        <Typography align='center' gutterBottom paragraph variant='h4'>
          <Box py={1} />
          <span style={{ color: 'rgb(0,255,0)' }}>
            一起創造新東西吧！<br />
            Make Something New, Together!
          </span>
          <br />
          <br />
          <Typography align='left' variant='h4'>
            現在就報名參加，開始與藝術家或科技人配對合作吧！
            <br />
            Get paired with an artist or technologist. Sign up now to begin the
            match process!
          </Typography>
        </Typography>
        <EmailSubmissionForm />
        <Box py={1} />
      </Box>
    </Box>
  )
}

// *****************************************************************************

function EmailSubmissionForm () {
  const validateSchema = Yup.object().shape({
    email: Yup.string()
      .required('請提供電子郵件 Email Required')
      .email('請提供正確的電子郵件 Please enter a valid email')
      //   .matches(/.*\.edu$/, 'Please enter a student email ending in .edu')
      //   // no plus addressed or period addressed emails because it'll break the
      //   // match respond logic
      .test({
        name: 'No plus alias',
        test: function doesEmailContainPlus (val) {
          let re = /\+/
          return !re.test(val)
        },
        message: '請勿使用帶有+號的電子郵件帳號 Please enter an email without plus aliasing'
      })
    //   .test({
    //     name: 'No period aliasing',
    //     test: function doesEmailContainPeriodAliasing (val) {
    //       const numPeriodsInVal =
    //         val !== undefined ? (val.match(/\./g) || []).length : 0
    //       return numPeriodsInVal < 2
    //     },
    //     message: 'Please enter an email without period aliasing'
    //   })
  })
  const [finished, setFinished] = useState(false)
  const styles = getFormStyles()
  const lessThan477 = useMediaQuery('(max-width:477px)') // for some reason, theme.breakpoints.down doesn't work here.

  return (
    <Box margin='0 auto' width='fit-content'>
      {!finished && (
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleEmailSubmission}
          render={EmailSubmissionFormInner}
          validationSchema={validateSchema}
          validateOnBlur
        />
      )}
      {finished && <ThankYouMessage />}
    </Box>
  )

  // **************************************
  // handleEmailSubmission tries to submit the email to the server. Returns
  function handleEmailSubmission (values, { setSubmitting, setFieldError }) {
    SubmitEmail(values.email).then(
      handleSuccessfulEmailResponse,
      handleUnsuccessfulEmailResponse
    )

    // **************
    function handleSuccessfulEmailResponse (response) {
      setSubmitting(false)
      if (response.data == 'OK') {
        setFinished(true)
      } else {
        setFieldError('email', response.data)
      }
    }

    function handleUnsuccessfulEmailResponse (response) {
      setSubmitting(false)
      setFieldError('email', '很抱歉，發生了一些問題。sorry. something went wrong')
    }
  }

  function EmailSubmissionFormInner ({ isSubmitting, status, ...props }) {
    return (
      <Form className={styles.emailForm}>
        <Grid container display='flex' className={styles.emailFieldRow}>
          <Grid item xs={12} sm={8}>
            <Field
              type='email'
              name='email'
              className={styles.emailField}
              placeholder='name@school.edu'
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              type='submit'
              disabled={isSubmitting}
              variant='contained'
              disableRipple
              className={styles.startButton}
            >
              <span style={{ marginBottom: '-6px' }}>
                <span
                  style={{ textTransform: 'lowercase', marginBottom: '4px' }}
                >
                  {'\u00d7'}
                </span>
                報名<br />Start
              </span>
            </Button>
          </Grid>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={12}>
            <ErrorMessage name='email' component={EmailFieldError} />
          </Grid>
        </Grid>
      </Form>
    )
  }
  function EmailFieldError ({ children }) {
    return (
      <Box
        style={{
          color: 'red',
          fontSize: '16px',
          paddingBottom: '10px',
          maxWidth: '100%',
          position: lessThan477 ? 'relative' : 'absolute',
          fontFamily: 'ArialCustom7x7, Arial, sans-serif',
          paddingTop: lessThan477 ? '0px' : '10px',
          marginBottom: lessThan477 ? '0px' : '10px'
        }}
      >
        {children}
      </Box>
    )
  }
}

function ThankYouMessage () {
  return (
    <>
      <span style={{ color: 'rgb(118,240,19)', marginBottom: '10px' }}>
        恭喜你！請到email信箱收信，獲得如何進行下一步的資訊！<br />
        如未收到，請記得檢查你的垃圾信箱，並將bot@7000x7000.tw 設為非垃圾郵件。
        <br />
        Congratulations! Check your email to find out more...<br />
        Be sure to whitelist bot@7000x7000.tw in your email client.
      </span>
      <Box py={1} />
    </>
  )
}
